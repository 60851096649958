@import "palette";
html {
  --primary-color: #004e83;

  svg {
    display: inline-block;
  }
}
html,
body {
  min-height: 100% !important;
  height: 100%;
}

main {
  height: 100%; //Showcase Page
  min-width: 1024px;
  min-height: 657px;
}

.showcase-wrapper {
  background-image: url("../Assets/dashboard-bg-img.jpg");
  height: 100%;
}

.showcase-header {
  height: 75px;
  .Left-nav-img {
    top: -25px;
  }
}

.showcase-content {
  height: 90%;
  position: absolute;
  top: 10%;
  width: 100%;
}

.App {
  height: 100%;
  font-family: "Roboto", sans-serif;
}
.showcase-content-wrapper {
  @extend .w-screen;
  height: calc(100% - 48px);
}
.navbar-item-label-active > div {
  box-shadow: 0 -1px inset $white;
  height: 65%;
}

.navbar-item-label-active > span {
  box-shadow: 0 -1px inset $white;
}

.main-content-wrapper {
  position: relative;
  padding-bottom: 3rem;
  min-height: calc(100% - 128px);

  &.no-footer {
    min-height: calc(100% - 48px);
  }

  > div {
    max-width: 60rem;
    margin: 2rem auto 0;
  }

  a:not(.no-underline) {
    text-decoration: underline;
  }
}

.link {
  a {
    text-decoration: underline;
  }
}

.background-img {
  object-fit: cover;
  position: absolute;
  height: calc(100% - 330px);
  max-height: 580px;
  width: 100%;
  top: 330px;
  left: 0;
  z-index: -1;
}

.h-65 {
  height: 65%;
}

.h-90 {
  height: 90%;
  max-height: calc(100% - 4rem);
}
.h-43 {
  height: 43%;
  max-height: calc(100% - 9rem);
}

.min-h-168px {
  min-height: 168px;
}
.min-h-24rem {
  min-height: 24rem;
}
.max-w-30 {
  max-width: 30px;
}

.h-78 {
  height: 78%;
}

.bg-opacity-90 {
  @media screen and (min-width: 768px) {
    --bg-opacity: 0.95;
  }
}

.mt-29px {
  margin-top: 29px;
}
.mt-20px {
  margin-top: 20px;
}

.mt-15px {
  margin-top: 15px;
}

.mt-10px {
  margin-top: 10px;
}

.w-112px {
  width: 112px;
}

.w-80px {
  width: 80px;
}

.w-140px {
  width: 140px;
}
.w-180px {
  width: 180px;
}
.w-50px {
  width: 50px;
  min-width: 50px;
}
.w-300px {
  width: 300px;
}
.w-342px {
  width: 342px;
}
.w-420px {
  width: 420px;
}
.max-w-40vw {
  max-width: 40vw;
}

.text-4-25xl {
  font-size: 2.5rem;
}
.text2rem {
  font-size: 2rem;
}
.w-45 {
  width: 45%;
}

.text-17px {
  font-size: 17px;
  text-transform: uppercase;
}
.text-22px {
  font-size: 24px;
}
.text-13px {
  font-size: 13px;
}

.big-button {
  width: 128px;
  height: 48px;
}
.shadow-gray {
  box-shadow: 0 0 20px 0 rgba($black, 0.3);
}
.sm-shadow-gray {
  box-shadow: 0 0 6px 0 rgba($black, 0.2);
}

.text-primary {
  color: var(--primary-color);
}
.fill-primary {
  fill: var(--primary-color);
}
.fill-gray {
  fill: $gray-darker;
}
.text-gray {
  color: $gray-darker;
}
.text-light-blue {
  color: $light-blue;
}

.text-error {
  color: $error;
}
.bg-blue {
  background-color: $blue;
}
.bg-gray-11 {
  background-color: rgba(0, 0, 0, 0.03);
}
.bg-gray-50 {
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-gray-75 {
  background-color: rgba(0, 0, 0, 0.75);
}

.bg-header-gray {
  background-color: rgba(7, 43, 79, 0.4);
}

.bg-primary {
  background-color: var(--primary-color);
}
.bg-primary-light,
.bg-primary-lighter,
.bg-primary-lighter-hover:hover {
  position: relative;
  background-color: var(--primary-color);

  > * {
    z-index: 1;
    position: relative;
  }

  &:after {
    content: "";
    background-color: white;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.95;
  }
}
.bg-primary-light:after {
  opacity: 0.9;
}
.z-2 {
  z-index: 2;
}

.flex-equal {
  flex: 1 1;
  flex-basis: 0;
}

.bg-secondary-lighter-hover:hover {
  position: relative;
  background-color: rgba(35, 81, 128, 0.7);

  > * {
    z-index: 1;
    position: relative;
  }

  &:after {
    content: "";
    background-color: transparent;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.95;
  }
}
.bg-secondary-light:after {
  opacity: 0.9;
}

.height {
  height: 100% !important;
}

.m-height {
  height: calc(100% - 11%);
}
.m-height-15 {
  height: calc(100% - 15%);
}
.m-height-25 {
  height: calc(100% - 25%);
}
.m-footer {
  bottom: 13px;
}

@for $size from 10 through 40 {
  .font-size-#{$size}px {
    font-size: #{$size}px;
  }
}

.login-wrapper {
  @media screen and (min-width: 768px) {
    padding: 35px 40px 30px 40px;
  }
}
@for $lines from 1 through 10 {
  .truncate-advanced-#{$lines} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: #{$lines};
    -webkit-box-orient: vertical;
  }
}
@for $size from 1 through 10 {
  .rounded-#{$size}px {
    border-radius: #{$size}px;
  }
}

@for $height from 1 through 40 {
  .line-height-#{$height}px {
    line-height: #{$height}px;
  }
}

.header-line {
  @media screen and (min-width: 768px) {
    line-height: 34px;
  }
}

.header-font-size {
  @media screen and (min-width: 768px) {
    font-size: 29px;
  }
  font-size: 23px;
}

.negative-margin {
  margin-left: -40px;
  margin-right: -40px;
}

@for $pixels from 1 through 40 {
  .py-#{$pixels}px {
    padding-left: #{$pixels}px;
    padding-right: #{$pixels}px;
  }
}

$directions: "top", "right", "bottom", "left";
@for $size from 0 through 25 {
  .m-#{$size}px {
    margin: #{$size}px;
  }

  @each $direction in $directions {
    .m-#{$direction}-#{$size}px {
      margin-#{$direction}: #{$size}px !important;
    }
  }
}
@for $size from 0 through 20 {
  .p-#{$size}px {
    padding: #{$size}px;
  }

  @each $direction in $directions {
    .p-#{$direction}-#{$size}px {
      padding-#{$direction}: #{$size}px !important;
    }
  }
}
@for $size_1 from 0 through 1 {
  @for $size_2 from 0 through 9 {
    .ls-#{$size_1}-#{$size_2}px {
      letter-spacing: #{$size_1 + $size_2/10}px;
    }
  }
}

@for $size from 1 through 10 {
  .opacity-#{$size}0 {
    opacity: #{$size / 10};
  }
}

.header-button {
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

/* Tooltip styling */
[data-tooltip] {
  display: inline-block;
  position: relative;
}

[data-tooltip]:before {
  content: attr(data-tooltip);
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.4;
  min-width: 100%;
  text-align: center;
  border-radius: 4px;
  white-space: break-spaces;
  transition: 0s;
  visibility: hidden;
}

[data-tooltip-position="top"]:before,
[data-tooltip-position="bottom"]:before {
  left: 50%;
  transform: translateX(-50%);
}

[data-tooltip-position="right"]:before,
[data-tooltip-position="left"]:before {
  top: 50%;
  transform: translateY(-50%);
}

[data-tooltip-position="top"]:before {
  bottom: 100%;
  margin-bottom: 6px;
}

[data-tooltip-position="right"]:before {
  left: 100%;
  margin-left: 6px;
}

[data-tooltip-position="bottom"]:before {
  top: 100%;
  margin-top: 6px;
}

[data-tooltip-position="left"]:before {
  right: 100%;
  margin-right: 6px;
}

[data-tooltip]:after {
  content: "";
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

[data-tooltip-position="top"]:after,
[data-tooltip-position="bottom"]:after {
  left: 50%;
  margin-left: -6px;
}

[data-tooltip-position="right"]:after,
[data-tooltip-position="left"]:after {
  top: 50%;
  margin-top: -6px;
}

[data-tooltip-position="top"]:after {
  bottom: 100%;
  border-width: 6px 6px 0;
  border-top-color: #000;
}

[data-tooltip-position="right"]:after {
  left: 100%;
  border-width: 6px 6px 6px 0;
  border-right-color: #000;
}

[data-tooltip-position="bottom"]:after {
  top: 100%;
  border-width: 0 6px 6px;
  border-bottom-color: rgba(0, 0, 0, 0.8);
}

[data-tooltip-position="left"]:after {
  right: 100%;
  border-width: 6px 0 6px 6px;
  border-left-color: #000;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
  transition-delay: 1s;
}

.register-form {
  position: absolute;
  top: 4%;
  padding: 4% 15%;
}
.register-message {
  margin: 35% 0%;
  text-align: center;
}

/*Welcome Page start here*/

.Bg-container {
  background: url(../Assets/welcome_Bg.jpg) no-repeat fixed bottom;
  background-size: cover;
  height: 100vh;
  position: relative;
  perspective: 200px;
  color: white;
}
.banner {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 16%;
  height: 36.5%;
  background: radial-gradient(#0065af, #004e88);
  position: absolute;
  top: 39.3%;
  left: 46.8%;
  padding: 10px;
  transform: skewY(6deg) perspective(100px) rotateY(2deg) rotateX(0.5deg);
  box-shadow: 5px 5px 5px #013f7a;
  text-align: center;
  color: #fff;
}
.img-banner {
  object-fit: contain;
  max-height: 8rem;
}
.img-wapper {
  display: flex;
  justify-content: center;
  height: 30%;
}
.arrow-down {
  margin: auto 50%;
  margin-top: 10px;
}
.side-banner-text {
  /* padding: 5px; */
  color: white;
  font-size: 11px;
  font-weight: bold;
}
.side-img {
  max-width: 80%;
  margin: 5px auto;
}

.side-banner {
  // background: radial-gradient(#0065af, #004e88);
  background-repeat: no-repeat;
  width: 13%;
  height: 16%;
  position: absolute;
  top: 70.6%;
  left: 5.8%;
  transform: skewY(12deg) perspective(55px) rotateX(0deg) rotateY(9deg)
    skewX(-2deg);
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .side-banner {
    transform: skewY(15deg) perspective(45px) rotateX(0deg) rotateY(9deg)
      skewX(-2deg);
  }

  .banner {
    transform: skewY(9deg) perspective(50px) rotateY(2deg) rotateX(0.5deg);
  }
}

@media screen and (min-width: 1025px) and (max-width: 1159px) {
  .side-banner {
    transform: skewY(15deg) perspective(45px) rotateX(0deg) rotateY(9deg)
      skewX(-2deg);
  }

  .banner {
    transform: skewY(8deg) perspective(50px) rotateY(2deg) rotateX(0.5deg);
  }
}

@media screen and (min-width: 1160px) and (max-width: 1359px) {
  .side-banner {
    transform: skewY(15deg) perspective(50px) rotateX(0deg) rotateY(9deg)
      skewX(-2deg);
  }
  .banner {
    transform: skewY(7deg) perspective(50px) rotateY(2deg) rotateX(0.5deg);
  }
}

@media screen and (min-width: 1280px) and (max-width: 1359px) {
  .side-banner {
    transform: skewY(14deg) perspective(55px) rotateX(0deg) rotateY(9deg)
      skewX(-2deg);
  }
  .banner {
    transform: skewY(7deg) perspective(50px) rotateY(2deg) rotateX(0.5deg);
  }
}

@media screen and (min-width: 1360px) and (max-width: 1439px) {
  .side-banner {
    transform: skewY(12deg) perspective(60px) rotateX(0deg) rotateY(9deg)
      skewX(-2deg);
  }
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .side-banner {
    transform: skewY(12deg) perspective(60px) rotateX(0deg) rotateY(9deg)
      skewX(-2deg);
  }
}

@media screen and (min-width: 1601px) and (max-width: 1720px) {
  .side-banner {
    transform: skewY(11deg) perspective(70px) rotateX(0deg) rotateY(9deg)
      skewX(-2deg);
  }
}

@media screen and (min-width: 1721px) {
  .side-banner {
    transform: skewY(13deg) perspective(85px) rotateX(0deg) rotateY(9deg)
      skewX(-2deg);
  }
}

.rollup-login {
  position: absolute;
  bottom: 3%;
  left: 29%;
  width: 40px;
}
.rollup-text {
  position: absolute;
  top: 10%;
  padding: 5px;
}
.pulse {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  opacity: 0.7;
  animation: animate 2s linear infinite;
}

.mobile-pulse {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  animation: animate 2s linear infinite;
}

@keyframes animate {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  40% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
  80% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.login-bg-img {
  background: url(../Assets/login_bg_img1.jpg) no-repeat fixed center;
  background-size: cover;
  height: 100vh;
  // display: block;
}
// .login-bg-container {
//   display: flex;
//   height: inherit;
//   position: relative;
// }
.date-text {
  color: #fff;
  text-transform: uppercase;
  padding: 5px;
  font-size: 11px;
  // margin: 8px;
}
.welcomeText {
  font-size: 1rem;
  // padding: 4px 0px;
  margin: 5px;
  font-weight: bold;
  padding-bottom: 15px;
  border-bottom: 1px solid #fff;
}
.form-container {
  position: absolute;
  top: 15%;
  width: 100%;
  padding: 0px 15%;
  height: 60%;
  // display: flex;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
  }
}
.form-bg-img {
  position: absolute;
  top: 0%;
  right: 0%;
  max-width: 37%;
  height: 100%;

  // min-width: 35%;
  // width: 36%;
  // max-width: 40%;
  // min-height: 50%;
  // max-height: 100%;
  // height: 100%;
  // display: flex;
  // justify-content: center;
  // min-width: 40%;
}
.admit-card {
  height: 100%;
}
.login-wapper-div {
  width: 25%;
  background: radial-gradient(#0063ae, transparent);
  border: 1px solid white;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  top: 1%;
  left: 15%;
  width: 30%;
  height: 60%;
  background: radial-gradient(#0065af, #004e88);
  text-align: center;
  padding: 2rem;
  margin-top: 1%;
}
.btn-container {
  align-items: center;
  color: #fff;
}
.text-forget {
  margin: 0 5%;
}
.login-form-logos {
  position: absolute;
  bottom: 8%;
  // right: 10%;
  display: flex;
  justify-content: space-between;
  width: 80%;
  // padding: 0 10%;
  align-items: baseline;
}
.client-img {
  width: 45%;
}
.logo {
  width: 40%;
  margin-top: 10%;
}

.bg-img-wapper {
  position: relative;
  width: 100%;
  margin: 0 0;
  padding: 0;
  max-width: 100%;
  height: inherit;
}

.support-page-wrapper {
  //height: 100vh;
  //background: url("../Assets/support-bg.jpg") no-repeat center center fixed;
  background-size: cover;
  min-width: 1024px;
}

.welcome-wrapper {
  // height: 100vh;
  // // margin: 0 auto;
  // background: url("../Assets/dashboard-bg-img.jpg") no-repeat center center
  //   fixed;
  // background-size: cover;
}

.home-bgImage {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.event-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  position: absolute;
  top: 19%;
  left: 42.5%;
  width: 15%;
  height: 20%;
  padding: 8px;
  text-align: center;
}
.img-wapper {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 65px;
}
.client-text {
  color: #e3f0f4;
  padding: 5px;

  .event-title {
    font-size: 0.875em;
    font-weight: bold;
    margin-top: 6px;
  }
}

.label-text {
  // font-size: 0.9375rem;
  font-weight: bold;
  color: #003d75;
  text-shadow: 1px 1px 2px #fff;
  cursor: pointer;
}

.navigation-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .each-link {
    position: absolute;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    // width: 14%;
    justify-content: center;
  }
}
.case-wapper {
  top: 63%;
  left: 22%;
}
.general-wapper {
  top: 44%;
  right: 22%;
}
.content-wapper {
  top: 66%;
  right: 22%;
}
.schedule-wapper {
  top: 44%;
  left: 22%;
}

.help-wapper {
  bottom: 20%;
  left: 0;
  margin: auto 41%;
}

.link-pulse {
  width: 15px;
  height: 15px;
  background: #003d75;
  border-radius: 50%;
  opacity: 0.7;
  animation: pulse 2s linear infinite;
  cursor: pointer;
  margin: 0 15px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #003d75;
  }
  40% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
  80% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.error-msg {
  color: #f0c96b;
}
.center-img {
  position: absolute;
  bottom: 31%;
  left: 50%;
  display: flex;
  justify-content: center;
  transform: translate(-50%, -50%);
  width: 120px;
}
.Left-nav-img {
  position: absolute;
  top: 0%;
  left: 0%;
  margin-left: 59px;
  width: 9.375em;
}
.right-nav-img {
  position: absolute;
  top: 0%;
  right: 0%;
  margin-right: 59px;
  width: 9.375em;
}
.logout-wapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 48%;
  left: 0%;
  width: inherit;
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
}
.right-wapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 48%;
  right: 0%;
  width: inherit;
  font-weight: bold;
  cursor: pointer;
}
.user-wapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  color: #fff;
  position: absolute;
  top: 53%;
  right: 0%;
  width: inherit;
}
.profile-img {
  width: 30px;
  margin-right: 5px;
}
.left-flagImg {
  position: absolute;
  bottom: 5%;
  left: 6.5%;
  width: 25px;
}
.right-flagImg {
  position: absolute;
  bottom: 5%;
  right: 5.5%;
  width: 25px;
}
.desk-img {
  position: absolute;
  bottom: 6%;
  left: 30%;
  width: 40%;
}
.rollup-banner {
  position: absolute;
  bottom: 6%;
  left: 3%;
  width: 9%;
  min-width: 130px;
  max-width: 177px;
}
.rollup-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 80%;
  top: 7%;
  width: 100%;
  transform: skewY(-3deg) perspective(75px) rotateY(5deg);
}
.rollup-date-text {
  padding: 0 10px;
  font-size: 0.875em;
}
.table-wapper {
  position: absolute;
  top: 9.5%;
  left: -1%;
  background: transparent;
  width: 66%;
  height: 75%;
  margin: auto 18.5%;
}
.table-list-wapper {
  height: 88%;
  overflow-y: scroll;
}

.schedule-date-text {
  margin-right: 12%;
}
.hr-line {
  border: 1px solid #235180;
}
.border-b {
  border-bottom: 2px solid #235180;
}
.w-95 {
  max-width: 95%;
}
.w-93 {
  max-width: 93.5%;
}

.support-logo-img {
  position: absolute;
  bottom: 36%;
  width: 25%;
  left: 37%;
}
.support-text {
  position: absolute;
  bottom: 10%;
  left: 45%;
  // font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #003d75;
  text-shadow: 1px 1px 1px #fff;
}
.place-pulse {
  position: absolute;
  bottom: 22%;
  left: 63%;
}

// .bg-img {
//   height: 100vh;
//   object-fit: fill;
//   object-position: center;
//   width: 100%;
// }

/* session page styles start here*/
.sessions-header-wrapper {
  position: fixed;
  background-color: #103665;
  z-index: 999;
  width: 62%;
}
// .session-bg-img {
//   width: 100%;
//   height: 100vh;
//   object-fit: fill;
//   object-position: top;
// }

.session-logo-img {
  position: absolute;
  bottom: 6%;
  left: 30%;
  width: 40%;
}

.meeting-logo-img {
  position: absolute;
  bottom: 6%;
  left: 30%;
  width: 40%;
}

.session-content {
  width: 66%;
  height: 66%;
  position: absolute;
  top: 9%;
  left: 16.5%;
  padding: 1rem 1rem 0.5rem 1rem;
}
.bg-card {
  background-color: transparent;
}

.session-rollup {
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 6%;
  text-align: center;
  color: rgb(255, 255, 255);
  transform: rotateY(30deg) ske skewX(0deg);
  transform: skewY(8deg) perspective(100px) rotateY(-7deg);
}

.session-rollup-wapper {
  position: absolute;
  bottom: 7%;
  right: 3%;
  min-width: 110px;
  max-width: 200px;
  width: 7%;
}

.meeting-content {
  position: absolute;
  top: 9%;
  left: 16.5%;
  width: 65.5%;
  height: 66%;
  padding: 2rem;
}

.meeting-Assigment {
  width: 25px;
  height: 30px;
  margin-right: 3%;
}

.meeting-calender {
  width: 25px;
  height: 20px;
  margin-right: 3%;
}
.meeting-clock {
  width: 25px;
  height: 20px;
  margin-right: 3%;
}

.exit-place {
  position: absolute;
  bottom: 40.5%;
  right: 18%;
  text-align: center;
  font-size: smaller;
}

.screen {
  position: absolute;
  top: 27.5%;
  left: 20%;
  width: 60.2%;
  height: 52%;
}

.agenda-rollup {
  padding: 10px;
  object-fit: contain;
}

.proceed-text {
  font-size: small;
  cursor: pointer;
  margin-top: 10px;
}
.session-rollup-text {
  font-size: small;
  margin-top: 20px;
  padding: 0px 5px;
  cursor: pointer;
}
.button {
  width: 100px;
  height: 40px;
}
.calendericon {
  width: 20px;
  height: 20px;
  opacity: 0.5;
  margin-right: 10px;
}
.bg-avatar {
  background-color: #f7cfcf;
}

.auditoriumLight {
  background-color: black;
  animation: breathing 4s ease-out normal;
  animation-fill-mode: forwards;
  height: 112%;
}

@keyframes breathing {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.55);
    transform-origin: 50% 70%;
  }
}

#auditorium-page {
  overflow: hidden;
}

.speaker-board {
  position: absolute;
  top: 17%;
  left: 10%;
  right: 10%;
  bottom: 20%;
  background: transparent
    radial-gradient(closest-side at 50% 50%, #11477e 0%, #0f3664 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 16px #0000007a;
  border: 2px solid #ffffff;
  opacity: 1;
  border-radius: 2px;
  padding: 1.5rem;
}
.speaker-button {
  background: #004f83 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 4px;
  opacity: 1;
}

// .login-wrap-zoom {
//   animation: zoomIn 1s;
//   animation-fill-mode: forwards;
// }

// @keyframes zoomIn {
//   0% {
//     transform: scale(0);
//   }
//   100% {
//     transform: scale(1);
//   }
// }

.table-list-wapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #103665;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.table-list-wapper::-webkit-scrollbar {
  width: 10px;
  background-color: #103665;
}

.table-list-wapper::-webkit-scrollbar-thumb {
  background-color: #fff;
  border: 2px solid #fff;
  // border-radius: 10px;
}
.session-scroll {
  height: calc(100% - 6rem);
  overflow-x: hidden;
}

.session-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #103665;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.session-scroll::-webkit-scrollbar {
  width: 10px;
  background-color: #103665;
}

.session-scroll::-webkit-scrollbar-thumb {
  background-color: #fff;
  border: 2px solid #fff;
  // border-radius: 10px;
}
.session-tags {
  width: 100%;
  overflow-x:scroll;
  white-space: nowrap;
}

.track-tag {
  padding: 5px 10px;
  font-size: 12px;
  border: 1px solid;
  border-radius: 50px;
  margin-right: 10px;
}

.track-tag.selected {
  background-color: #fff;
  color: #285B8C;
  border: 1px solid #fff;
}

@media only screen and (min-width: 1536px) {
  .admit-text {
    font-size: 50px;
  }
  .login-text {
    font-size: 30px;
    margin-top: 20px;
  }
  .rollup-date-text {
    font-size: 12px;
    padding: 0 15px;
  }
  .agenda-rollup {
    width: 145px;
    padding: 16px;
  }
  .proceed-text {
    font-size: 12px;
    margin-top: 20px;
    padding: 10px;
  }
  .session-rollup-text {
    font-size: 12px;
    width: 125px;
  }
  .form-bg-img {
    max-width: 40%;
    height: 100%;
  }
  .admit-card {
    height: inherit;
  }
}
.fieldContainer {
  width: 45%;
}

//mobile design styles

.mobile-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.mobile-my-schedule-meeting-wrap  {
  height: 65vh;
}

.overlay {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
}

.mobile-layout {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
}

.sidebar {
  background: #064489;
  z-index: 99999999;
  position: relative;
}

.card-container {
  top: 0%;
  left: 0%;
  padding: 1.5rem 1rem;
}

.card-shadow {
  box-shadow: 0px 6px 18px rgba(0, 30, 59, 0.8);
}

.auditorium-img-wapper {
  position: relative;
  width: 100%;
  margin: 0 0;
  padding: 0;
  max-width: 100%;
  height: inherit;
}

.card-item {
  background: rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  position: relative;
}
.list-scroll::-webkit-scrollbar {
  display: none;
}

.mobile-only {
  display: none;
}

.clock-icon {
  position: absolute;
  left: 10px;
  top: 20px;
  height: 13px;
}
// .nav-position {
//   position: sticky;
//   top: 0;
//   background-color: #004e83;
//   z-index: 999;
// }

.cross-icon {
  width: 25px;
  height: 25px;
  border: 1px solid #000;
  border-radius: 50%;
  text-align: center;
  background-color: #fff;
  top: 10px;
  z-index: 9999999;
}

@media only screen and (max-width: 1000px) {
  #auditorium-page {
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 1023px) {
  .mobile-only {
    display: block;
  }

  .bg-img-wapper {
    display: none;
  }
  main {
    // height: auto;
    min-width: 100%;
    // min-height: 550px;
  }
  .btn-container {
    justify-content: space-between;
  }
  .fieldContainer {
    width: 100%;
  }
  // .register-form-container {
  //   height: 82%;
  //   overflow-y: scroll;
  //   margin-top: 10px;
  // }
  .register-form-container::-webkit-scrollbar {
    display: none;
  }
  .register-text {
    font-size: 1.5rem;
  }
  .mobile-img-bg {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  .text-forget {
    margin: 5% 5%;
  }
  .showcase-wrapper {
    display: none;
  }
  .desktop-content {
    display: none;
  }
  #auditorium-page {
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 400px) {
  .xs-300 {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1024px) {
  .mobile-container {
    display: none;
  }
  .mobile-content {
    display: none;
  }
  .bg-card:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}

.bottom-15 {
  bottom: 15%;
}
