@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/montserrat-regular-webfont.woff2') format('woff2'),
  url('/fonts/montserrat-regular-webfont.woff') format('woff'),
  url('/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/montserrat-italic-webfont.woff2') format('woff2'),
  url('/fonts/montserrat-italic-webfont.woff') format('woff'),
  url('/fonts/Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/montserrat-medium-webfont.woff2') format('woff2'),
    url('/fonts/montserrat-medium-webfont.woff') format('woff'),
    url('/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/montserrat-mediumitalic-webfont.woff2') format('woff2'),
  url('/fonts/montserrat-mediumitalic-webfont.woff') format('woff'),
  url('/fonts/Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/montserrat-semibold-webfont.woff2') format('woff2'),
  url('/fonts/montserrat-semibold-webfont.woff') format('woff'),
  url('/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/montserrat-semibolditalic-webfont.woff2') format('woff2'),
  url('/fonts/montserrat-semibolditalic-webfont.woff') format('woff'),
  url('/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/montserrat-bold-webfont.woff2') format('woff2'),
  url('/fonts/montserrat-bold-webfont.woff') format('woff'),
  url('/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}